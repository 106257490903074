import { CodeableConcept } from "fhir"

import { unitOfTime } from "data"
import { SYSTEM_VALUES } from "system-values"

import { DispenseIntervalOption } from "./types"

const dispenseInterval: DispenseIntervalOption[] = [
  {
    label: "One time",
    value: {
      value: 0,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Monthly",
    value: {
      value: 1,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 2 months",
    value: {
      value: 2,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 3 months",
    value: {
      value: 3,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 4 months",
    value: {
      value: 4,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 5 months",
    value: {
      value: 5,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 6 months",
    value: {
      value: 6,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
]

enum MEDICATIONS_REGULATIONS_CODE {
  MAX_DISPENSE = "max-dispense",
  RESTRICTED_SHIPPING = "shipping-not-allowed",
  MAX_DAYS_SUPPLY = "max-days-supply",
}

const MEDS_ORDER_TYPE_MAP: {
  [key: string]: { module?: string; subview?: { completed: string; default: string }; orderParam?: string }
} = {
  "nutraceutical-order": {
    module: "nutraceuticals",
    subview: { completed: "orders", default: "orders" },
    orderParam: "order",
  },
  "medication-order": {
    module: "pharmaceuticals",
    subview: { completed: "history", default: "orders" },
    orderParam: "order",
  },
  unknown: {
    module: "dashboard",
    subview: undefined,
    orderParam: undefined,
  },
}

export enum RefillModeCodes {
  AUTO_ORDER = "auto-order",
  ON_DEMAND = "on-demand",
  ONE_TIME = "one-time",
}

const REFILL_MODES: Record<RefillModeCodes, CodeableConcept> = {
  [RefillModeCodes.AUTO_ORDER]: {
    coding: [{ code: RefillModeCodes.AUTO_ORDER, system: SYSTEM_VALUES.MEDICATION_REFILL_MODE, display: "Auto Order" }],
  },
  [RefillModeCodes.ON_DEMAND]: {
    coding: [{ code: RefillModeCodes.ON_DEMAND, system: SYSTEM_VALUES.MEDICATION_REFILL_MODE, display: "On Demand" }],
  },
  [RefillModeCodes.ONE_TIME]: {
    coding: [{ code: RefillModeCodes.ONE_TIME, system: SYSTEM_VALUES.MEDICATION_REFILL_MODE, display: "One Time" }],
  },
}

export { dispenseInterval, MEDICATIONS_REGULATIONS_CODE, MEDS_ORDER_TYPE_MAP, REFILL_MODES }
