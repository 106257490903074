import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"

import { formatsByTypes } from "data"
import { formatDate } from "utils"
import { SkeletonLoader } from "commons"
import { useLoginContext } from "security"

import { useCarePlanHistory } from "../../hooks"
import { CarePlanHistory } from "../../types"

const AppointmentHistory = ({ carePlanId, selectedItemId, onSelectHisoryItem }: Props) => {
  const { loggedInPatientId: patientId } = useLoginContext()

  const { isLoading, appointmentHistory, nextAppointment, hasAppointment } = useCarePlanHistory(patientId, carePlanId)

  return (
    hasAppointment && (
      <div className="flex flex-col w-80 bg-gray-50 divide-y divide-gray-200 rounded-md">
        <div className="flex flex-col flex-1 p-5">
          {isLoading ? (
            <SkeletonLoader loaderType="two-lines" repeats={4} />
          ) : (
            <>
              <h1 className="font-medium text-lg text-gray-900">Appointment history</h1>
              <div className="flex flex-col mt-2 overflow-y-auto">
                {nextAppointment && (
                  <>
                    <h2 className="font-medium text-gray-700">Next appointment</h2>
                    <CPItem
                      cpItem={nextAppointment}
                      selected={selectedItemId === nextAppointment.carePlan.id}
                      onClick={() => onSelectHisoryItem(nextAppointment.carePlan.id as string)}
                    />
                    {appointmentHistory.length > 0 && (
                      <h2 className="font-medium text-gray-700 mt-4 border-t border-gray-200 pt-4">
                        Previous appointments
                      </h2>
                    )}
                  </>
                )}
                {appointmentHistory.length
                  ? appointmentHistory.map((cp) => (
                      <CPItem
                        key={cp.carePlan.id}
                        cpItem={cp}
                        selected={selectedItemId === cp.carePlan.id}
                        onClick={() => onSelectHisoryItem(cp.carePlan.id as string)}
                      />
                    ))
                  : !nextAppointment && <span className="font-medium text-gray-700 flex-1">Empty history</span>}
              </div>
            </>
          )}
        </div>
      </div>
    )
  )
}

const CPItem = ({ cpItem, selected, onClick }: CPItemProps) => (
  <div
    key={cpItem.carePlan.id}
    className={classNames(
      "flex space-x-2 items-center text-gray-500 rounded-sm mt-1 p-2",
      selected ? "bg-gray-200 cursor-default" : "cursor-pointer",
    )}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faCalendar} />
    <span className="font-medium text-sm">
      {cpItem.appointment?.start || cpItem.carePlan?.created
        ? formatDate(
            new Date(cpItem?.appointment?.start ?? (cpItem.carePlan?.created as string)),
            formatsByTypes.FULL_DATETIME,
          )
        : "No date"}
    </span>
  </div>
)

type Props = { carePlanId: string; selectedItemId: string; onSelectHisoryItem(planId: string): void }

type CPItemProps = { cpItem: CarePlanHistory; selected?: boolean; onClick(): void }

export { AppointmentHistory }
