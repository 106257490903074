import { useSuspenseQuery } from "@tanstack/react-query"
import { Bundle, Patient, Reference, asReference, getResources, isPatient, isUser } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { CustomError } from "commons"
import { registerErrorTrace } from "logger"

import { useAuth } from "../hooks/useAuth"

const useLoggedUserPatients = () => {
  const { search } = useClient()
  const { user } = useAuth()

  const queryKey = ["logged-user-patients", user.linkedUser.id]

  const { data, isLoading, isPending, error } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      let patients: Patient[] = []
      let bundle: Bundle | undefined

      if (isUser(user.linkedUser)) {
        const filters = new URLSearchParams({
          id: user.linkedUser.id ?? "",
          _include: "link:Patient",
        })

        bundle = await search({ endpoint: "User", filters })
      } else if (isPatient(user.linkedUser)) {
        const filters = new URLSearchParams({
          id: user.linkedUser.id ?? "",
          "active:not": "false",
        })

        bundle = await search({ endpoint: "Patient", filters })
      }

      if (bundle) {
        patients = getResources<Patient>(bundle, "Patient").filter((pat) => pat.active !== false)
      }

      if (!patients.length) {
        throw registerErrorTrace(
          new Error("Forbidden", {
            cause: {
              name: "403",
              message: "Forbidden Access",
              logoutFlag: true,
            },
          }) as CustomError,
          { cause: "Maybe it is a practitioner trying to access without a patient linked to its user" },
        )
      }

      return patients
    },
    meta: { context: { queryKey, user } },
  })

  const { patientRefs, patientsIndexed } = useMemo(() => {
    const { patientRefs, patientsIndexed } = data.reduce<{
      patientRefs: Reference[]
      patientsIndexed: Record<string, Patient>
    }>(
      (patientsData, patient) => {
        if (patient.id && patient.active !== false) {
          return {
            ...patientsData,
            patientRefs: [...patientsData.patientRefs, asReference(patient)],
            patientsIndexed: { ...patientsData.patientsIndexed, [patient.id]: patient },
          }
        }

        return patientsData
      },
      { patientRefs: [] as Reference[], patientsIndexed: {} },
    )

    return { patientRefs, patientsIndexed }
  }, [data])

  const isLinkedPatient = (id: string) => patientRefs.some((ref) => ref.id === id)

  return {
    patients: data,
    patientsIndexed,
    patientRefs: patientRefs,
    isLinkedPatient,
    linkedPatientsCount: data.length,
    isLoading: isLoading || isPending,
    error,
  }
}

export { useLoggedUserPatients }
