import { useSearchParams } from "react-router-dom"
import { ClientProvider } from "@chartedhealth/payment-components"
import { useMemo } from "react"

import { useClient } from "api"
import { useLoginContext } from "security"
import { useBasisTheoryInstance } from "account"
import { datadogLogs } from "logger"

import { InvoiceListContainer } from "./InvoiceListContainer"
import { PaymentContainer } from "./PaymentContainer"

const InvoiceContainer = () => {
  const [searchParams] = useSearchParams()
  const invoiceId = searchParams.get("invoiceId")
  const paymentId = searchParams.get("paymentId")
  const { search, getSignedUrl, operationRequest, read, patch } = useClient()
  const { loggedInPatientId } = useLoginContext()
  const { bt } = useBasisTheoryInstance()

  const loggedInUserResource = useMemo(
    () => ({ resourceType: "Patient", id: loggedInPatientId, isAdmin: false }),
    [loggedInPatientId],
  )

  if (invoiceId || paymentId) {
    return (
      <ClientProvider
        apm={datadogLogs.logger}
        bt={bt}
        loggedInUserResource={loggedInUserResource}
        search={search}
        getSignedUrl={getSignedUrl}
        operationRequest={operationRequest}
        read={read}
        patch={patch}
      >
        <PaymentContainer invoiceId={invoiceId} paymentId={paymentId} />
      </ClientProvider>
    )
  }

  return <InvoiceListContainer />
}

export { InvoiceContainer }
