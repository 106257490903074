import { FieldProps } from "formik"
import { classNames } from "primereact/utils"
import { HTMLInputTypeAttribute, LegacyRef, PropsWithChildren, forwardRef } from "react"
import { InputText } from "primereact/inputtext"
import { InputMask } from "primereact/inputmask"

import { FormField, FormFieldBaseProps } from "./FormField"

export const InputField = forwardRef<HTMLInputElement, PropsWithChildren<Props>>(function MyInput(
  {
    type = "text",
    mask,
    unmask,
    inputClassName,
    disabled,
    autocomplete,
    className,
    placeholder,
    spellcheck,
    ...fieldProps
  },
  inputRef,
) {
  return (
    <FormField className={className ? `${className} ${type}` : `${type}`} {...fieldProps}>
      {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) =>
        mask ? (
          <InputMask
            type={type}
            mask={mask}
            unmask={unmask}
            id={name}
            name={name}
            onChange={onChange}
            value={value}
            disabled={disabled}
            className={classNames(
              { "p-invalid": touched && error, horizontal: fieldProps.horizontal },
              "p-inputtext-sm h-10",
              inputClassName,
            )}
            ref={inputRef as LegacyRef<InputMask>}
          />
        ) : (
          <InputText
            autoComplete={autocomplete}
            spellCheck={spellcheck}
            aria-autocomplete="none"
            type={type}
            id={name}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            disabled={disabled}
            className={classNames(
              { "p-invalid": touched && error, horizontal: fieldProps.horizontal },
              "p-inputtext-sm h-10",
              inputClassName,
            )}
            ref={inputRef as LegacyRef<InputText>}
          />
        )
      }
    </FormField>
  )
})

type Props = FormFieldBaseProps<string> & {
  type?: HTMLInputTypeAttribute
  mask?: string
  unmask?: boolean
  inputClassName?: string
  disabled?: boolean
  autocomplete?: "off"
  placeholder?: string
  spellcheck?: boolean
}
