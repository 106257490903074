import { classNames } from "primereact/utils"

const Badge = ({ text, colorStyle = "gray", size = "md", className }: BadgeProps) => {
  const colorVariants = {
    gray: "bg-gray-100 text-gray-800",
    red: "bg-red-100 text-red-800",
    yellow: "bg-yellow-100 text-yellow-800",
    green: "bg-green-100 text-green-800",
    blue: "bg-blue-100 text-blue-800",
    indigo: "bg-indigo-100 text-indigo-800",
    purple: "bg-purple-100 text-purple-800",
    pink: "bg-pink-100 text-pink-800",
    orange: "bg-orange-100 text-orange-800",
  }
  return (
    <span
      className={classNames(
        `items-center rounded-full py-0.5 font-medium ${colorVariants[colorStyle]}`,
        { "px-2.5 text-xs": size === "md" },
        { "px-3 text-sm": size === "lg" },
        className,
      )}
    >
      {text}
    </span>
  )
}

export type BadgeProps = {
  className?: string
  text: string
  colorStyle?: "gray" | "red" | "yellow" | "green" | "blue" | "indigo" | "purple" | "pink" | "orange"
  size?: "md" | "lg"
}

export { Badge }
