import { faSpinner } from "@fortawesome/pro-light-svg-icons"
import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactNode } from "react"

const InfoRow = ({ title, content, loading, editable, onEdit }: Props) => (
  <div className="flex w-full items-center text-gray-400 text-sm border-b border-gray-200 last:border-0 py-5 first:pt-0">
    <p className="w-1/5 min-w-[5rem] font-semibold">{title}</p>
    <div className="w-3/5">{content}</div>
    {loading && (
      <div className="text-right w-1/5 pr-4">
        <FontAwesomeIcon icon={faSpinner} spin={loading} />
      </div>
    )}
    {editable && !loading && (
      <div className="text-right w-1/5 pr-2">
        <span className="hover:bg-gray-100 rounded-full p-2 cursor-pointer" title="Edit" onClick={onEdit}>
          <FontAwesomeIcon icon={faEdit} className="text-gray-600" />
        </span>
      </div>
    )}
  </div>
)

type Props = {
  title: string
  content: string | ReactNode
  loading?: boolean
  editable?: boolean
  onEdit?(): void
}

export { InfoRow }
