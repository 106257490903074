import { IconDefinition, faPhone, faClose } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Toast } from "primereact/toast"

import { useEffect, useRef, useState } from "react"

import { NotificationVCType } from "../data"
import { TNotificationSummaryVC, TNotificationVC } from "../types"
import { useBrowserNotification, useVideoCallContext, type TNotificationBuild } from "../hooks"

import chIconLogo from "images/chIconLogo.webp"

export const NotificationVideoCall = ({ type = "success" }: TNotificationVC) => {
  const [visible, setVisible] = useState(false)
  const [missing, setMissing] = useState(false)
  const { handleHangUpVC, handleJoinCall, recieveVC, setNotificationType } = useVideoCallContext()
  const { handleNotification } = useBrowserNotification()
  const toastVC = useRef<Toast>(null)
  const showNotification = !!Object.keys(recieveVC).length

  const NotificationSummaryVC: TNotificationSummaryVC = {
    [NotificationVCType.success]: `Sent you an invite to connect.`,
    [NotificationVCType.error]: `You have a missed call.`,
  } as const

  const BrowserNotification: TNotificationBuild = {
    title: `${recieveVC?.practitionerFullName}`,
    options: {
      body: `${NotificationSummaryVC[type]}`,
      tag: "VideoCall",
      requireInteraction: true,
      icon: chIconLogo,
    },
  }

  const clearVisibility = () => {
    toastVC.current?.clear()
    setVisible(false)
  }

  const clear = () => {
    clearVisibility()
    type !== NotificationVCType.error && handleHangUpVC()
  }

  const join = () => {
    clearVisibility()
    handleJoinCall()
  }

  const resetNotificationType = () => {
    if (type === NotificationVCType.error) {
      setMissing(true)
    }
    clearVisibility()
  }

  useEffect(() => {
    if (missing) {
      confirm()
      setMissing(false)
    }
  }, [visible])

  useEffect(() => {
    !visible && !missing && setNotificationType(undefined)
  }, [missing])

  useEffect(() => {
    if (showNotification && type === NotificationVCType.success) {
      confirm()
      setNotificationType(undefined)
    }
  }, [recieveVC])

  useEffect(() => resetNotificationType(), [type])

  const confirm = () => {
    if (!visible) {
      toastVC.current?.clear()
      setVisible(true)
      handleNotification(BrowserNotification)
      toastVC.current?.show({
        // severity: NotificationVCType[type],
        summary: recieveVC?.practitionerFullName,
        detail: NotificationSummaryVC[NotificationVCType[type]],
        contentClassName: "border-none backdrop:blur-xs bg-white/60 rounded-lg ring-1 ring-black/5 shadow-lg",
        sticky: true,
        closable: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        content: (props) => (
          <div className="flex flex-col items-start gap-4 w-full">
            <div className="inline-flex justify-between w-full">
              <div className="text-sm">
                <p className=" font-medium text-gray-900">{props.message.summary}</p>
                <p className="mt-1 text-gray-500">{props.message.detail}</p>
              </div>
              <IconCallBtn mode="close" fontIcon={faClose} onClick={clear} className="h-5 w-5" />
            </div>
            <div className="w-full flex justify-start gap-4">
              <IconCallBtn text="Accept" hidden={type === NotificationVCType.error} fontIcon={faPhone} onClick={join} />
              <IconCallBtn text="Decline" fontIcon={faPhone} mode="danger" onClick={clear} />
            </div>
          </div>
        ),
      })
    }
  }

  return (
    <div className={`${!visible ? "hidden" : "flex"} justify-content-center z-10`}>
      <Toast ref={toastVC} position="top-right" className="w-10/12 sm:w-full" />
    </div>
  )
}

const styleModeBtn = (mode: TModeBtn = "success") => {
  if (mode === "success")
    return "rounded-md text-sm bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  if (mode === "danger")
    return "rounded-md text-sm bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
  if (mode === "close")
    return "rounded-full justify-center text-gray-400 hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
}

const IconCallBtn = ({ fontIcon, onClick, mode = "success", hidden, text, className }: TIconCallBtn) => (
  <button
    onClick={onClick}
    className={`${hidden ? "hidden" : "inline-flex"} gap-2 items-center ${styleModeBtn(mode)} ${className}`}
  >
    <FontAwesomeIcon className={`${mode === "danger" && "rotate-[135deg] text-gray-900"}`} icon={fontIcon} />
    {text}
  </button>
)

type TModeBtn = "danger" | "success" | "close"

type TIconCallBtn = {
  hidden?: boolean
  fontIcon: IconDefinition
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  mode?: TModeBtn
  text?: string
  className?: string
}
