import { faCheck, faCheckDouble, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { format } from "date-fns"

import { AvatarImage } from "commons"
import { formatsByTypes } from "data"

const BubbleMessage = ({
  avatar,
  name,
  date,
  message,
  className,
  grouped = false,
  own = false,
  sended = false,
  received = false,
  thread_close = false,
}: Props) => (
  <div
    className={classNames(
      "message-bubble relative flex mb-3 text-sm",
      own && "flex-row-reverse",
      grouped && "-mt-2",
      className,
    )}
  >
    {!own && (
      <div className="bubble-avatar flex left-0 top-0 bottom-0 absolute flex-col-reverse">
        <AvatarImage className="sticky bottom-0 rounded-full w-10 h-10" name={name} photoUrl={avatar} />
      </div>
    )}
    <div className="ml-12 mr-5 max-w-[75%]">
      <div
        className={classNames(
          "px-3 py-1 rounded-xl border border-gray-200 shadow",
          thread_close && (own ? "rounded-br-sm" : "rounded-bl-sm"),
        )}
      >
        {name && !own && <div className="font-semibold mb-2 text-start">{name}</div>}
        <span className="break-words line-clamp-6">{message}</span>
        <span className="flex justify-end text-sm text-slate-400">
          {date && format(date, formatsByTypes.TIME)}
          {own &&
            ((sended && !received && (
              <div className="ml-2">
                <FontAwesomeIcon icon={faCheck} />
              </div>
            )) ||
              (received && (
                <div className="ml-2">
                  <FontAwesomeIcon icon={faCheckDouble} />
                </div>
              )) || (
                <div className="ml-2">
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
              ))}
        </span>
      </div>
    </div>
  </div>
)

type Props = {
  avatar?: string
  name: string
  date?: Date
  message: string
  grouped?: boolean
  own?: boolean
  sended?: boolean
  received?: boolean
  className?: string
  thread_close?: boolean
}

export { BubbleMessage }
