import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { PublicClientApplication } from "@azure/msal-browser"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import { MsalProvider } from "@azure/msal-react"
import { PrimeReactProvider } from "primereact/api"
import { twMerge } from "tailwind-merge"

import { init as loggerInit } from "logger"
import App from "App"
import { dataDogInit } from "datadog"
import { NetworkContextProvider } from "check-network"
import AppContainer from "AppContainer"
import { Home, HomeContextProvider } from "home"
import { NotFoundView } from "navigation"
import { AuthProvider } from "security"

import { msalConfig } from "./authConfig"
import "./index.css"
import "react-toastify/dist/ReactToastify.css"
import reportWebVitals from "./reportWebVitals"

const container = document.getElementById("root")
const root = createRoot(container as HTMLElement)

loggerInit()
dataDogInit()

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig)

root.render(
  <StrictMode>
    <PrimeReactProvider
      value={{
        pt: {},
        ptOptions: {
          mergeSections: true,
          mergeProps: true,
          classNameMergeFunction: twMerge,
        },
      }}
    >
      <NetworkContextProvider>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <AuthProvider>
              <AppContainer>
                <HomeContextProvider>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/patient/:patientId/*" element={<App />} />
                    <Route path="*" element={<NotFoundView />} />
                  </Routes>
                </HomeContextProvider>
              </AppContainer>
            </AuthProvider>
          </BrowserRouter>
        </MsalProvider>
      </NetworkContextProvider>
    </PrimeReactProvider>
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
