import { Reference, ResourceObject, asReference, isPatient } from "fhir"
import { useMsal } from "@azure/msal-react"
import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser"
import { createContext, ReactNode, useCallback, useEffect, useState, PropsWithChildren, useRef } from "react"
import { differenceInMinutes } from "date-fns"
import { datadogRum as dataDog } from "datadog"
import { useLocation } from "react-router-dom"

import { datadogLogs, registerErrorTrace } from "logger"
import { LoadingView, CustomError } from "commons"
import { AuthError } from "errors"
import { loginRequest, setupTOTPRequest } from "authConfig"

const AuthContext = createContext<State | undefined>(undefined)
AuthContext.displayName = "AuthContext"

const DEFAULT_RENEW_TOKEN_TIMEOUT = 3 // Wait 3 minutes by default due the min time allowed is 5 minutes

const isDevelop = process.env.NODE_ENV === "development"

const userMock = isDevelop && import.meta.env.VITE_APP_MOCK_USER && JSON.parse(import.meta.env.VITE_APP_MOCK_USER)

const AuthProvider: React.FC<PropsWithChildren<Props>> = ({ children }: { children: ReactNode }) => {
  const { pathname, search } = useLocation()
  const { instance } = useMsal()
  const [isLoading, setIsloading] = useState(!userMock)
  const [user, setUser] = useState<User | undefined>(userMock)
  const [error, setError] = useState<Error | undefined>()
  const refreshTokenTimer = useRef<NodeJS.Timeout | null>(null)
  const retryRenewTokenCount = useRef(0)

  const clearRefreshTokenTimer = () => {
    if (refreshTokenTimer.current) {
      clearTimeout(refreshTokenTimer.current)
    }
  }

  const initializeUser = (response: AuthenticationResult) => {
    instance.setActiveAccount(instance.getAccountByHomeId(response.account?.homeAccountId))

    const name = response.account?.name ?? "unspecified"
    const email = (response.idTokenClaims as { email: string })?.email ?? "unspecified email"
    const token = `${response.tokenType} ${response.accessToken}`
    let logId = email

    const linkedUser = getLinkedUser(response)
    const b2cUserId = (response.idTokenClaims as IdTokenClaims)["sub"]

    if (!b2cUserId) {
      datadogLogs.setUser({ id: response.account?.homeAccountId ?? email, name, email })

      setError(
        new Error("Unauthorized", {
          cause: { name: "401", message: `No B2C user linked to ${name}` },
        }),
      )
    } else if (!linkedUser) {
      datadogLogs.setUser({ id: response.account?.homeAccountId ?? email, name, email })

      setError(
        new Error("Missing resource", {
          cause: { name: "401", message: `No patient linked to user ${name} with email ${email}` },
        }),
      )
    } else {
      logId = linkedUser.id ?? email

      datadogLogs.setUser({ id: logId, name, email })

      setUser({ name, email, token, linkedUser, b2cUserId })
    }

    dataDog.setUser({
      id: logId,
      name: name,
      email: email,
    })

    setIsloading(false)
  }

  const tryRenewAccessToken = async () => {
    const account = instance.getActiveAccount()

    if (account) {
      const request = {
        ...loginRequest,
        account,
      }

      // Silently acquires an access token which is then attached to a request for aidbox data
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          const interval = getRenewTokenInterval(response)

          initializeUser(response)
          clearRefreshTokenTimer()

          retryRenewTokenCount.current = 0
          refreshTokenTimer.current = setTimeout(() => tryRenewAccessToken(), interval)
        })
        .catch(async (error) => {
          if (
            error.errorMessage.includes("AADB2C90077") ||
            error.errorMessage.includes("AADB2C90091") ||
            error.errorMessage.includes("AADB2C90080") ||
            error instanceof InteractionRequiredAuthError
          ) {
            await instance.loginRedirect(loginRequest)
          } else if (/post_request_failed/.test(error?.message)) {
            if (retryRenewTokenCount.current <= 2) {
              retryRenewTokenCount.current++

              tryRenewAccessToken()
            } else {
              await instance.loginRedirect(loginRequest)
            }
          } else {
            setError(new Error(error.errorCode, { cause: { name: error.name, message: error.errorMessage } }))
          }
        })
        .finally(() => setIsloading(false))
    } else {
      await instance.loginRedirect(loginRequest)
    }
  }

  const checkAccount = async () => {
    await instance.initialize()

    await instance
      .handleRedirectPromise()
      .then(async (response) => {
        // temp log to check the thing
        console.info(response)
        if (response && response.account) {
          const interval = getRenewTokenInterval(response)

          initializeUser(response)
          clearRefreshTokenTimer()

          refreshTokenTimer.current = setTimeout(() => tryRenewAccessToken(), interval)
        } else {
          const account = instance.getActiveAccount()

          if (!account) {
            await instance.loginRedirect(loginRequest)
          } else {
            await tryRenewAccessToken()
          }
        }
      })
      .catch(async (error) => {
        setIsloading(false)

        if (
          error.errorMessage.includes("AADB2C90077") ||
          error.errorMessage.includes("AADB2C90091") ||
          error.errorMessage.includes("AADB2C90080") ||
          error instanceof InteractionRequiredAuthError
        ) {
          await instance.loginRedirect(loginRequest)
        } else if (error?.errorMessage?.includes("AADB2C99002")) {
          setError(
            new Error(error.errorCode, {
              cause: {
                name: "No account found",
                message: "We couldn't find an account matching your information.",
              },
            }),
          )
        } else if (error?.errorMessage?.includes("AADB2C90273")) {
          setError(
            new Error(error.errorCode, {
              cause: {
                name: "Access denied",
                message: "The resource owner or authorization server denied the request.",
              },
            }),
          )
        } else {
          setError(new Error(error.errorCode, { cause: { name: error.name, message: error.errorMessage } }))
        }
      })
  }

  useEffect(() => {
    if (!userMock) checkAccount()
  }, [])

  const setupTOTP = useCallback(() => {
    instance.acquireTokenRedirect(setupTOTPRequest)
  }, [instance])

  const setupSMS = useCallback(() => {
    window.location.href = `${window.VITE_APP_AUTH_URL}/phone-setup?redirectUrl=${window.location.href}`
  }, [])

  const logout = useCallback(
    async (isSessionExpired?: boolean) => {
      const account = instance.getActiveAccount()

      if (account) {
        const logoutRequest = {
          account: instance.getAccountByHomeId(account.homeAccountId),
          postLogoutRedirectUri: isSessionExpired ? `${pathname}${search}` : "/",
        }
        await instance.logoutRedirect(logoutRequest)
      }
    },
    [instance, pathname, search],
  )

  const setLinkedResource = useCallback(
    (resource: ResourceObject) => {
      const linkedResource = asReference(resource)

      if (!isPatient(linkedResource)) {
        setError(
          new Error("Unauthorized", {
            cause: {
              name: "401",
              message: `Sorry ${
                linkedResource.display ?? "Unknown tenant"
              }, but you don't have permission to access to Patient Portal. If you think it is a mistake, contact to support.`,
            },
          }),
        )
      } else {
        if (user) {
          datadogLogs.setUser({ id: linkedResource.id, name: user.name, email: user.email })
          setUser({ ...user, linkedResource })
        }
      }
    },
    [user],
  )

  if (isLoading) {
    return <LoadingView />
  }

  if (error) {
    const customError = registerErrorTrace(error as CustomError)
    const isCORSError = error?.message === "AADB2C90002"
    return <AuthError error={customError} logout={logout} shouldRetry={isCORSError} />
  }

  if (!user) {
    const error = new Error("Unauthorized", {
      cause: {
        name: "401",
        message: "You cannot log in without a valid user.",
      },
    })
    const customError = registerErrorTrace(error as CustomError)

    return <AuthError error={customError} logout={logout} />
  }

  const value = {
    user,
    logout,
    setupTOTP,
    setupSMS,
    setLinkedResource,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const getRenewTokenInterval = (response: AuthenticationResult) => {
  const diff = response.expiresOn ? differenceInMinutes(response.expiresOn, new Date()) : DEFAULT_RENEW_TOKEN_TIMEOUT

  return Math.floor((diff * 2) / 3) * 60000
}

const getLinkedUser = (response: AuthenticationResult): Reference | undefined => {
  try {
    const claimUsers = JSON.parse((response.idTokenClaims as IdTokenClaims)["aidbox/users"]) as string[]

    if (claimUsers?.length) {
      const user = claimUsers.find((user) => user.includes("evexias"))

      if (user) {
        const [, id] = user.split("|")

        return {
          id,
          resourceType: "User",
        }
      }
    }

    if ((response.idTokenClaims as IdTokenClaims)["user/id"]) {
      return {
        id: (response.idTokenClaims as IdTokenClaims)["user/id"],
        resourceType: "User",
      }
    }
  } catch (error) {
    console.error(error)
    return undefined
  }
}

type IdTokenClaims = {
  "user/id": string
  "aidbox/users": string
  sub: string
}

type State = {
  user: User
  setupTOTP(): void
  setupSMS(): void
  logout(isSessionExpired?: boolean): void
  setLinkedResource(resource: ResourceObject): void
}

type Props = {
  children: ReactNode
}

export type User = {
  email: string
  name: string
  token: string
  linkedResource?: Reference
  linkedUser: Reference
  b2cUserId: string
}

export { AuthProvider, AuthContext }
