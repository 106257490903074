import { faChevronDown, faChevronUp, faPrint } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, parseISO } from "date-fns"
import { codeableConceptAsString } from "fhir"
import { Button } from "primereact/button"
import { classNames } from "primereact/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom"

import {
  DropdownMenu,
  SimpleBreadCrumb,
  SkeletonLoader,
  useSidebarContext,
  getOrderDate,
  useShowSignedUrlDocument,
} from "commons"
import { BILLING_TYPES_CODES, LAB_REFERENCE_ID_CODE, billingTypes, formatsByTypes } from "data"
import { useLoginContext } from "security"
import { getMoneyCurrencyAlt, getSubviewPath, openLinkInNewTab } from "utils"

import { useLaboratoryOrder } from "../hooks"
import { getStatus } from "../utils"
import { LaboratoryOrderDetailsInvoice } from "./LaboratoryOrderDetailsInvoice"
import { LaboratoryOrderResults } from "./LaboratoryOrderResults"
import { LaboratoryTaskItem } from "./LaboratoryTaskItem"

const LaboratoryOrderDetails = () => {
  const navigate = useNavigate()
  const { loggedInPatientId } = useLoginContext()
  const { organizationId, isSmallScreen } = useSidebarContext()
  const { showDocument: asyncShowDocument } = useShowSignedUrlDocument(openLinkInNewTab)
  const [params, setParams] = useSearchParams()

  const [expandedReason, setExpandedReason] = useState(false)
  const [isLoadingDocs, setIsLoadingDocs] = useState(false)

  const orderId = params.get("order")
  const goToOrders = () => {
    params.delete("order")
    setParams(params)
  }

  useEffect(() => {
    if (!orderId) goToOrders()
  })

  const { laboratoryOrder, tasks, invoice, isLoading, requisition, diagnosticReport } = useLaboratoryOrder(
    organizationId,
    orderId as string,
    loggedInPatientId,
  )

  const handleShowDoc = useCallback(
    async (url: string) => {
      setIsLoadingDocs(true)
      await asyncShowDocument(url).finally(() => setIsLoadingDocs(false))
    },
    [asyncShowDocument],
  )

  const showInvoice = useCallback(() => {
    navigate({
      pathname: getSubviewPath(loggedInPatientId, "invoices"),
      search: `?${createSearchParams({ invoiceId: invoice?.id as string })}`,
    })
  }, [invoice, loggedInPatientId, navigate])

  const activeActions = useMemo(() => {
    const dropdownItems = [
      {
        label: "View results",
        icon: <FontAwesomeIcon icon={faPrint} size="sm" className="mr-2" />,
        disabled: !laboratoryOrder.presentedForm || !laboratoryOrder.latestResult?.released,
        command: () => handleShowDoc(laboratoryOrder.presentedForm ?? ""),
      },
      {
        label: "View requisition",
        icon: <FontAwesomeIcon icon={faPrint} size="sm" className="mr-2" />,
        disabled: !requisition,
        command: () => {
          openLinkInNewTab(requisition ?? "")
        },
      },
    ]

    return dropdownItems
  }, [laboratoryOrder.presentedForm, requisition, handleShowDoc])

  const { insuranceLab: isInsuranceLab, billToPracticeLab: isBillToPracticeLab } = useMemo(() => {
    return {
      insuranceLab: laboratoryOrder.billingType === "insurance",
      billToPracticeLab: laboratoryOrder.billingType === "bill-practice",
    }
  }, [laboratoryOrder.billingType])

  const labOrderStatus = getStatus(laboratoryOrder.order)

  const labReferenceId =
    laboratoryOrder.order?.identifier?.find(({ type }) => type?.coding?.some((c) => c.code === LAB_REFERENCE_ID_CODE))
      ?.value ?? "unavailable ID"

  return (
    <div className="flex flex-col h-full overflow-hidden p-3 lg:px-5 pb-0">
      <SimpleBreadCrumb goHome={goToOrders} title="Order Details" />
      {isLoading ? (
        <>
          <SkeletonLoader loaderType="two-lines" extraLine repeats={3} />
        </>
      ) : (
        <div className="@container flex flex-col flex-1 gap-4 overflow-hidden pb-6 pt-3">
          <div className="flex flex-col border-b border-gray-200 pb-4 gap-1">
            <div className="flex justify-between">
              <span title="Performer" className="text-primary font-medium text-lg">
                {laboratoryOrder.order?.performer?.[0].display ?? "unspecified"}
              </span>
              <div className="inline-flex items-center gap-2">
                {isSmallScreen ? (
                  <DropdownMenu dropdownMenuItems={activeActions} loading={isLoadingDocs} />
                ) : (
                  <>
                    {!activeActions[0].disabled && (
                      <Button
                        className="p-button-sm p-button-text"
                        label={activeActions[0].label}
                        icon={activeActions[0].icon}
                        onClick={activeActions[0].command}
                        loading={isLoadingDocs}
                      />
                    )}
                    {!activeActions[1].disabled && (
                      <Button
                        className="p-button-sm p-button-text"
                        label={activeActions[1].label}
                        icon={activeActions[1].icon}
                        onClick={activeActions[1].command}
                        loading={isLoadingDocs}
                        disabled={activeActions[1].disabled}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex text-sm flex-wrap gap-3 justify-stretch gap-x-5">
              <div className="flex gap-1">
                <label className="text-gray-900">Requester:</label>
                <span className="text-gray-400">{laboratoryOrder.requester}</span>
              </div>
              <div className="flex gap-1">
                <label className="text-gray-900">Date:</label>
                <span className="text-gray-400">{getOrderDate(laboratoryOrder.order)}</span>
              </div>
              {
                <div className="flex gap-1">
                  <label className="text-gray-900">Lab Reference ID:</label>
                  <span className="text-gray-400">{labReferenceId}</span>
                </div>
              }
              <div className="flex gap-1">
                <label className="text-gray-900">Status:</label>
                <span className="text-gray-400 capitalize">
                  {labOrderStatus?.code === "revoked" ? "cancelled" : labOrderStatus?.display ?? "unspecified"}
                </span>
              </div>
              {laboratoryOrder.billingType !== BILLING_TYPES_CODES.BILL_PRACTICE && (
                <div className="flex gap-1">
                  <label className="text-gray-900">Billing type:</label>
                  <span className="text-gray-400">
                    {billingTypes.find(({ code }) => code === laboratoryOrder.billingType)?.display}
                  </span>
                </div>
              )}
              {!isInsuranceLab && !isBillToPracticeLab && (
                <div className="flex gap-1">
                  <label className="text-gray-900">Price:</label>
                  <span className="text-gray-400">{`${getMoneyCurrencyAlt(
                    laboratoryOrder.price?.currency,
                  )}${laboratoryOrder.price?.value?.toFixed(2)}`}</span>
                </div>
              )}
              {laboratoryOrder.specimenDate && (
                <div className="flex gap-1">
                  <label className="text-gray-900">Blood drawn in the office on:</label>
                  <span className="text-gray-400">
                    {format(parseISO(laboratoryOrder.specimenDate), formatsByTypes.LONG_DATETIME_WITH_TIMEZONE)}
                  </span>
                </div>
              )}
              {laboratoryOrder.order?.insurance?.[0]?.display && (
                <div className="flex gap-1">
                  <label className="text-gray-900">Insurance:</label>
                  <span className="text-gray-400">{laboratoryOrder.order.insurance[0].display}</span>
                </div>
              )}
            </div>
          </div>

          {!!diagnosticReport?.conclusion && (
            <pre className="rounded-lg p-2 bg-gray-50 text-sm">{diagnosticReport?.conclusion}</pre>
          )}

          <div className="flex flex-col gap-4 overflow-y-scroll pr-1">
            <LaboratoryOrderResults
              panels={laboratoryOrder.panels}
              combo={laboratoryOrder.combo}
              previousResults={laboratoryOrder.previousResults}
              hidePrices
            />

            {!!laboratoryOrder.order?.reasonCode?.length && (
              <div
                className="border border-gray-200 p-4 rounded-lg transition-all ease-in-out duration-1000 cursor-pointer"
                role="button"
                onClick={() => setExpandedReason(!expandedReason)}
              >
                <div className="flex justify-between">
                  <label>Reason codes</label>
                  <div className="flex gap-4 text-gray-900">
                    <span className="text-sm text-gray-300">{expandedReason ? "Show less" : "Show more"} </span>
                    <FontAwesomeIcon icon={expandedReason ? faChevronUp : faChevronDown} />
                  </div>
                </div>

                <div className={classNames("text-gray-400", { hidden: !expandedReason })}>
                  <ul className="mt-3 flex flex-wrap gap-x-8 whitespace-nowrap list-disc pl-4 text-sm">
                    {laboratoryOrder.order.reasonCode.map((reason, index) => (
                      <li
                        key={
                          reason.id ?? (reason.coding?.[0].code as string) + index ?? (reason.text as string) + index
                        }
                        className="mb-1 p-1 last:mb-3 text-wrap whitespace-normal break-words"
                      >
                        {reason.coding
                          ? `${codeableConceptAsString(reason)} - ${reason.coding?.[0]?.code}`
                          : reason.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {!!tasks?.length && (
              <div className="flex flex-col gap-4 pt-8 mt-6 border-t border-gray-200 pr-1">
                <label className="text-gray-900 font-semibold text-sm ml-2">Tasks:</label>
                <ul className="border border-gray-200 rounded-lg p-4">
                  {tasks?.map((task) => <LaboratoryTaskItem key={task.id} task={task} />)}
                </ul>
              </div>
            )}

            {invoice?.recipient?.id === loggedInPatientId && !isBillToPracticeLab && (
              <span className="flex flex-col gap-4 pt-8 mt-6 border-t border-gray-200 pr-1">
                <label className="text-gray-900 font-semibold text-sm ml-2">Invoice: </label>
                <ul className="border border-gray-200 rounded-lg p-4">
                  <LaboratoryOrderDetailsInvoice invoice={invoice} onClick={showInvoice} />
                </ul>
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export { LaboratoryOrderDetails }
