import { AccountBETACreditCardArray } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { classNames } from "primereact/utils"

import { CreditCardImage, CreditCardType } from "account"

const OrderCreditCardListItem = ({ creditCard, selected = false, onSelect, disabled }: Props) => (
  <div
    className={classNames(
      "relative flex rounded-lg border border-gray-200 bg-white p-4 shadow-xs focus:outline-hidden gap-4 hover:shadow-md",
      selected ? "border-transparent ring-1 ring-primary" : "border-gray-300",
      { "cursor-pointer": !disabled && !selected },
    )}
    onClick={() => !disabled && !selected && onSelect(creditCard)}
  >
    <CreditCardImage creditCardType={creditCard.type as CreditCardType} />
    <div className="flex flex-1">
      <div className="flex flex-col">
        <span className="font-medium text-gray-900 text-sm">X-{creditCard.last4Digits}</span>
        <span className="mt-1 flex items-center text-sm text-gray-500">{`${creditCard.expirationMonth?.padStart(
          2,
          "0",
        )}/${creditCard.expirationYear?.substring(2)}`}</span>
      </div>
    </div>
    {selected && <FontAwesomeIcon icon={faCircleCheck} className="h-5 w-5 text-primary" />}
    <span
      className={classNames(
        "pointer-events-none absolute -inset-px rounded-lg",
        selected ? "border border-primary" : "border-2 border-transparent",
      )}
    />
  </div>
)

type Props = {
  creditCard: AccountBETACreditCardArray
  selected: boolean
  disabled?: boolean
  onSelect(creditCard: AccountBETACreditCardArray): void
}
export { OrderCreditCardListItem }
