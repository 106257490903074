import {
  faCalendarCheck,
  faCalendarDays,
  faFileInvoiceDollar,
  faPills,
  faPrescriptionBottleMedical,
  faReceipt,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, parseISO } from "date-fns"
import { codeableConceptAsString, isMedicationRequest } from "fhir"
import { MenuItem } from "primereact/menuitem"
import { FC } from "react"

import { formatsByTypes } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getBadgeColor, getInvoicesInfo } from "utils"

import { Badge } from "../../components/Badge"
import { ListItem } from "../../components/ListItem"
import { MedicationRequestOrderData } from "../types"

const MedicationOrderListItem: FC<Props> = ({ mrOrderData, onItemClicked, isLoading, dropdownMenuItems }) => {
  const orderMRs = mrOrderData.serviceRequest.basedOn?.filter(isMedicationRequest)
  const lifeFileId = mrOrderData.serviceRequest.identifier?.find(
    ({ system }) => system === SYSTEM_VALUES.LIFEFILE_MEDICATION_ORDER,
  )
  const medOrderIdentifier = mrOrderData.serviceRequest.identifier?.find(
    ({ system }) => system === SYSTEM_VALUES.MEDICATION_ORDER,
  )

  const pharmacyStatus = mrOrderData.medicationDispense
    ?.flatMap(({ statusReason }) => codeableConceptAsString(statusReason?.CodeableConcept, ""))
    ?.filter((status) => !!status)

  const head = (
    <span className="inline-flex space-x-1">
      <p title="Order number">{`${medOrderIdentifier?.value ?? "Unspecified number"}`}</p>
      {!!lifeFileId && <p title="LifeFile Reference">({lifeFileId.value})</p>}
    </span>
  )

  const { identifier: invoiceNumber } = getInvoicesInfo(mrOrderData.invoices)

  const dataDetails = [
    {
      title: "Authored On",
      awsIcon: faCalendarDays,
      data: mrOrderData.serviceRequest.authoredOn
        ? format(parseISO(mrOrderData.serviceRequest.authoredOn), formatsByTypes.LONG_DATE)
        : "Unspecified",
    },
    {
      title: "Ocurrence",
      awsIcon: faCalendarCheck,
      data: mrOrderData.serviceRequest.occurrence?.dateTime
        ? format(parseISO(mrOrderData.serviceRequest.occurrence.dateTime), formatsByTypes.LONG_DATE)
        : "Unspecified",
    },
    { title: "Medication Requests", awsIcon: faPills, data: orderMRs?.length ?? 0 },
    {
      title: "Invoice number",
      awsIcon: faFileInvoiceDollar,
      data: invoiceNumber?.length ? `#${invoiceNumber.join(", #")}` : "no invoice",
    },
    {
      title: "Payment Reconciliation",
      awsIcon: faReceipt,
      data: `Payment: ${mrOrderData.payRec?.paymentStatus ?? "no payment info"}`,
    },
    ...(pharmacyStatus?.length
      ? [
          {
            title: "Pharmacy Status",
            awsIcon: faPrescriptionBottleMedical,
            data: `Pharmacy Status: ${pharmacyStatus.join(", ")}`,
          },
        ]
      : []),
  ]

  const showOcurrence = () =>
    mrOrderData.serviceRequest.occurrence?.dateTime || mrOrderData.serviceRequest.authoredOn ? "flex" : "hidden"

  const details = (
    <>
      {dataDetails.map(({ data, awsIcon, title }, index) => (
        <span key={index} title={title} className={`${index ? "flex md:pr-2" : showOcurrence()} items-center gap-1`}>
          <FontAwesomeIcon icon={awsIcon!} className="text-gray-400" />
          <p className="translate-y-[1px] capitalize">{data}</p>
        </span>
      ))}
    </>
  )
  const chip = <Badge {...getBadgeColor(mrOrderData.serviceRequest.status)} />

  return (
    <ListItem
      header={head}
      chip={chip}
      subHeader={details}
      dropdownMenuItems={dropdownMenuItems?.(mrOrderData)}
      onContentClick={onItemClicked}
      isLoading={isLoading}
    />
  )
}

type Props = {
  mrOrderData: MedicationRequestOrderData
  onItemClicked(): void
  isLoading?: boolean
  dropdownMenuItems?(item: MedicationRequestOrderData): MenuItem[]
}

export { MedicationOrderListItem }
