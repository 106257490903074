import { codeableConceptAsString, Task } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarCheck, faCalendarDays } from "@fortawesome/pro-solid-svg-icons"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"

import { ListItem } from "commons"
import { taskPriorityCodes } from "data"
import { getTaskDate, taskDateToString } from "utils"

const LaboratoryTaskItem = ({ task }: Props) => {
  const getPriorityColor = (priority: string) => {
    return {
      "is-danger": priority === taskPriorityCodes[3].code,
      "is-warning": priority === taskPriorityCodes[2].code,
      "is-info": priority === taskPriorityCodes[1].code,
      "is-secondary": priority === taskPriorityCodes[0].code,
    }
  }

  const getStatusColor = (status: string) => {
    return {
      "is-info": status === "ready",
      "is-secondary": status === "requested",
      "is-warning": status === "on-hold",
      "is-draft": status === "entered-in-error",
      "is-success": status === "completed",
      "is-danger": status === "cancelled",
    }
  }

  const Head = (
    <>
      {task.code && (
        <p title="Code" className="whitespace-nowrap overflow-hidden text-ellipsis">
          {codeableConceptAsString(task.code)}
        </p>
      )}
    </>
  )
  const CustomChip = (
    <>
      {task.status && (
        <span title="Status">
          <Chip
            label={task.status}
            className={classNames("ml-2 custom-chip custom-chip", getStatusColor(task.status))}
          />
        </span>
      )}
      {task.priority && (
        <span title="Priority">
          <Chip label={task.priority} className={classNames("ml-2 custom-chip", getPriorityColor(task.priority))} />
        </span>
      )}
    </>
  )

  const Details = (
    <>
      <span title="Authored on" className="flex items-center">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
        {taskDateToString(task.authoredOn as string)}
      </span>
      <span title="Scheduled for" className="flex items-center">
        <FontAwesomeIcon icon={faCalendarCheck} className="mr-1.5 text-gray-400 md:pl-2" />
        {getTaskDate(task)}
      </span>
    </>
  )

  return (
    <ListItem
      header={Head}
      subHeader={Details}
      chip={CustomChip}
      className="border-b border-gray-200 last:border-b-0 pb-1"
    />
  )
}

type Props = {
  task: Task
}

export { LaboratoryTaskItem }
